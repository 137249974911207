import axios from "axios";
import {useCatStatesStore} from "./Stores/CatStatesStore";

/**
 * Fetch the data needed to populate the Pinia stores
 * @async
 * @returns {Promise<void>}
 */
export default async () => {
  // const catsStore = useCatsStore();
  const catStatesStore = useCatStatesStore();
  // const usersStore = useUsersStore();

  // usersStore.setCurrentUser(getUser());

  await axios({
    method: "post",
    url: "/start-up",
  })
    .then((response) => {
      catStatesStore.loadStates(response.data.catStates);
      // catsStore.cats = response.data.cats;
      // trivialFacts.facts = response.data.facts;
      // usersStore.userList = response.data.userList;

      // catsStore.showAll();
    })
    .catch((errors) => {
      console.error(errors);
    });

  // recoverUserPreferences();
  // monitorUserPreferences();
};
