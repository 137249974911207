import CKEditor from "@ckeditor/ckeditor5-vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { createInertiaApp } from "@inertiajs/vue3";
import * as Sentry from "@sentry/vue";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { createPinia } from "pinia";
import { createApp, h } from "vue";
import matomo from "vue-matomo";
import { ZiggyVue } from "ziggy";
import { Ziggy } from "../artifacts/ziggy";
import "../css/app.scss";
import "./FontAwesomeLibrary";
import "./bootstrap";
import MatomoConfig from "./config/Matamo";
import startup from "./startup";
import { getEnv } from "./utilities";

const debug = true;
const dsn =
  "https://eadea0a3e2d46e4d3eea8082a2a35c53@o4506317490159616.ingest.sentry.io/4506355235094528";
const environment = "production";

const appName =
  window.document.getElementsByTagName("title")[0]?.innerText ||
  "Buttons Cat Rescue";
const pinia = createPinia();

Sentry.init({
  dsn,
  debug,
  environment,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [
        "localhost",
        "192.168.0.3",
        /^https:\/\/(www|staging|prod)\.buttonscatrescue\.co\.uk/,
      ],
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

createInertiaApp({
  title: (title) => title,
  resolve: (name) =>
    resolvePageComponent(
      `./Pages/${name}.vue`,
      import.meta.glob("./Pages/**/*.vue"),
    ),
  setup({ el, App, props, plugin }) {
    const appInstance = createApp({ render: () => h(App, props) })
      .component("font-awesome-icon", FontAwesomeIcon)
      .use(CKEditor)
      .use(matomo, MatomoConfig)
      .use(pinia)
      .use(plugin)
      .use(ZiggyVue, Ziggy)
      .mount(el);

    // This will attach Vue's app to Sentry's scope
    Sentry.configureScope((scope) => {
      scope.setTag("vue version", appInstance.version);
    });

    return appInstance;
  },
  progress: {
    color: "#4B5563",
  },
}).then(() => {
  getEnv();
  startup();
});
