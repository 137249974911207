import {defineStore} from "pinia";
import * as _ from "lodash-es";
import {ref} from 'vue';

type CatState = {
    adoptable: 0 | 1;
    applicable_to: "single" | "virtual" | "both";
    default_priority: number;
    id: number | string;
    state: string;
    date_created: string | Date;
    date_updated: string | Date;
}
type CatStateList = CatState[];

export const useCatStatesStore = defineStore("cat-states", {
    state: () => ({
        loaded: ref(false),
        states: ref([] as CatStateList),
        adminOnlyStates: [
            "deceased",
            "foster_home",
            "unlisted",
            "waiting_list",
        ],
    }),

    actions: {
        loadStates(states: CatStateList) {
            this.states = states;
            this.loaded = true
        },
        publicIds() {
            return _.filter(this.states, (state) => {
                return _.includes(this.adminOnlyStates, state.state, 0, true);
            });
        },
        isPublicState(id) {
            return !_.includes(this.adminOnlyStates, id, 0, true);
        },
    },
});
