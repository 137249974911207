"use strict";
import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faArrowUpRightFromSquare,
    faBan,
    faBlog,
    faBookBible,
    faCalendar,
    faCamera,
    faCar,
    faCaretLeft,
    faCaretRight,
    faCat,
    faCircleExclamation,
    faCircleInfo,
    faCircleQuestion,
    faClipboardList,
    faCode,
    faEye,
    faFileContract,
    faHandDots,
    faHourglassEnd,
    faHouseCircleCheck,
    faLeftLong,
    faLink,
    faMagnifyingGlass,
    faMars,
    faMicrochip,
    faPenSquare,
    faPenToSquare,
    faPeopleGroup,
    faPersonCirclePlus,
    faPersonCircleQuestion,
    faPersonShelter,
    faPhotoFilm,
    faScaleBalanced,
    faShieldCat,
    faSignature,
    faSkullCrossbones,
    faSort,
    faSortDown,
    faSortUp,
    faSpinner,
    faTrash,
    faTriangleExclamation,
    faTrowel,
    faUser,
    faUsers,
    faVenus,
    faVenusMars
} from '@fortawesome/free-solid-svg-icons'

library.add(
    faMagnifyingGlass,
    faSpinner,
    faPenSquare,
    faCaretLeft,
    faCaretRight,
    faUser,
    faPersonCircleQuestion,
    faCat,
    faUsers,
    faClipboardList,
    faFileContract,
    faBlog,
    faPhotoFilm,
    faSignature,
    faSort,
    faSortDown,
    faSortUp,
    faEye,
    faTrash,
    faPenToSquare,
    faCalendar,
    faPersonShelter,
    faTrowel,
    faShieldCat,
    faHourglassEnd,
    faBan,
    faPersonCirclePlus,
    faMicrochip,
    faCar,
    faBookBible,
    faCamera,
    faHouseCircleCheck,
    faCode,
    faScaleBalanced,
    faHandDots,
    faLink,
    faLeftLong,
    faTriangleExclamation,
    faCircleExclamation,
    faPeopleGroup,
    faMars,
    faVenus,
    faVenusMars,
    faCircleQuestion,
    faSkullCrossbones,
    faCircleInfo,
    faArrowUpRightFromSquare,
);
