/**
 * Things stopping change to TS:
 *  route()
 */
import {usePage} from "@inertiajs/vue3";
import * as _ from "lodash-es";
import Swal from "sweetalert2";
import config from "./config/App";

// const route = window.ziggy.route;

export let systemUnderTest = null;

export function strToUpper(lower) {
    if (typeof (lower) !== 'string') {
        return '';
    }

    const words = lower.split(" ");

    return words
        .map((word) => {
            if (word.length === 0) {
                return '';
            }

            return word[0].toUpperCase() + word.substring(1);
        })
        .join(" ");
}

export function SwalContent() {
    const errors = [
        "Oh... Rat droppings!",
        "Aaagghhh - Crap in a cookie",
        "Of all the errors...",
        "I#039;m so sorry about this",
    ];

    const confirm = {
        good: ["OK", "Awesome!", "Brilliant!"],
        bad: ["If you must!", "Oh Bugger!"],
    };

    return {
        errors: _.shuffle(errors)[0],
        confirm: {
            good: _.shuffle(confirm.good)[0],
            bad: _.shuffle(confirm.bad)[0],
        },
    };
}

export function getUser() {
    if (_.isEmpty(usePage().props.user)) {
        // reauthenticate();
    }
    return usePage().props.user;
}

export function getEnv() {
    const env = usePage().props.env;

    if (env === "testing" || env === "dev") {
        systemUnderTest = true;
    }

    return env;
}

export function testing() {
    return systemUnderTest;
}

/**
 * Get the CSRF Token from the property passed by Inertia
 */
export function getCsrfToken() {
    return usePage().props.token;
}

/**
 * Scroll to the top of the page, mainly used when a page view to the same "Page" is called
 */
export function scrollToTop(id = "app") {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
}

export function pronounDecider(value) {
    let details;
    let virtual;

    switch (value) {
        case "y":
        case Boolean(true):
            virtual = true;
            break;
        case "n":
        case Boolean(false):
            virtual = false;
            break;
        default:
            console.error("Unknown value passed to pronounDecider");
    }

    if (virtual) {
        details = {
            wereOrIm: "we're",
            weOrI: "we",
            usOrMe: "us",
            ourOrMy: "our",
            areOrAm: "are",
        };
    } else {
        details = {
            wereOrIm: "I'm",
            weOrI: "I",
            usOrMe: "me",
            ourOrMy: "my",
            areOrAm: "am",
        };
    }
    return details;
}

/**
 * Get a valid recaptcha token from the Google API
 *
 * @example getRecaptchaToken('register', token => { console.log(token)})
 * @param   action    The action to associate the token with
 * @param   cb        The callback that should be executed once the token has been received
 * @returns {Promise<void>}
 * @since 0.9.2
 */
export async function getRecaptchaToken(action, cb) {
    // eslint-disable-next-line no-undef
    window["grecaptcha"].ready(function () {
        // eslint-disable-next-line no-undef
        window["grecaptcha"]
            .execute(config.recaptcha.siteKey, { action: "register" })
            .then(function (token) {
                cb(token);
            });
    });
}

/**
 * Add a SweetAlert mixin in order to create an easier toast
 *
 * @example Toast.fire({ icon: 'success', title: 'Cat added' });
 * @since 0.9.2
 */
export const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
});

/**
 * Add a SweetAlert mixin in order to create an easier toast
 *
 * @example Toast.fire({ icon: 'success', title: 'Cat added' });
 * @since 0.9.2
 */
export const SwalAlert = Swal.mixin({
    showConfirmButton: true,
    confirmButtonText: SwalContent().confirm.good,
    showCancelButton: false,
});

/**
 * Make it easier for axios errors to be logged by standardizing it
 */
export const logAxiosError = (message, error, ...data) => {
    console.groupCollapsed(message, [error.statusCode]);
    console.info("response...");
    console.error(error);
    if (data) {
        console.dir(data);
    }
    console.groupEnd();
};

/**
 * Convert an email address to a link to itself
 */
export const emailLink = (email) => {
    return (
        "<a " +
        'href="mailto:' +
        email +
        '" ' +
        "@click=\"trackExternalLink('mailto:'" +
        email +
        ",'Email link to " +
        email +
        "')\"" +
        ">" +
        email +
        "</a>"
    );
};

/**
 * Convert a telephone number to a link to itself
 */
export const telLink = (tel) => {
    return (
        "<a " +
        'href="tel:' +
        tel +
        '" ' +
        "@click=\"trackExternalLink('tel:'" +
        tel +
        ",'telephone link to " +
        tel +
        "')\"" +
        ">" +
        tel +
        "</a>"
    );
};

/**
 * Until I get TypeScript support for route(), I'll just create a stopgap function
 */
export const routeWithDefault = (passedRoute, defaultHref) => {
    // console.log("url", route[0]);
    // console.log("id", route[1]);
    if (Array.isArray(passedRoute) && _.isFunction("route")) {
        let url = passedRoute(passedRoute[0], passedRoute[1]);
        console.log("url", url);
        return url;
    } else if (_.isFunction("route")) {
        let url2 = passedRoute(passedRoute);
        console.log("url2", url2);
        return url2;
    }
    return defaultHref.toString();
};
