/**
 * Global JS config file for the front-end
 *
 * @returns {{tracking: boolean}}
 */
export default {
    appName: 'Buttons Cat Rescue',
    /**
     * Default telephone number
     */
    defaultTelephoneNumber: "+447464466443",

    /**
     * Whether the users' activities on the site should be tracked by Matomo
     */
    tracking: true,

    /**
     * Active pages
     * As the site needs to become active A.S.A.P, I'll cut some of the pages out until they are fully complete
     */
    pages: {
        home: {
            title: "Home",
            active: true,
            href: "/",
        },
        about: {
            title: "About",
            active: true,
            href: "/about",
        },
        volunteeringIndex: {
            title: "Volunteering",
            active: true,
            href: "/volunteering",
        },
        volunteeringBecomeAFosterer: {
            title: "Become A Fosterer",
            active: false,
            href: "/volunteering/become-a-fosterer",
        },
        help: {
            title: "Getting Help?",
            active: false,
            href: "/help",
        },
        catsIndex: {
            title: "Cats Home Page",
            active: true,
            href: "/cats",
        },
        // catShow:      {
        //     title:  'An Interview with...',
        //     active: true,
        //     href:   route('public.cats.show'),
        // },
        dashboard: {
            title: "Dashboard",
            active: true,
            href: "/dashboard",
        },
        login: {
            title: "Login",
            active: true,
            href: "/login",
        },
        logout: {
            title: "Logout",
            active: true,
            href: "/logout",
        },
        register: {
            title: "Register",
            active: true,
            href: "/register",
        },
        contact: {
            title: "Contact Us",
            active: true,
            href: "/contact",
        },
        posts: {
            title: "Posts",
            active: false,
            href: "/posts",
        },
    },

    /**
     * Images
     */
    images: {
        staticFiles: "https://buttonscatrescue-website.s3.eu-west-2.amazonaws.com/static-images",
        directories: {
            cats: {
                base: "cats",
                thumbnails: "thumbs",
            },
            posts: {
                base: "posts",
                thumbnails: "thumbs",
            },
        },
        sizes: {
            thumbnails: {
                width: 240,
                height: 171,
            },
            optimized: {
                width: 804,
                height: 574,
            },
        },
        breakpoints: {},
        allowedHeros: [
            'home',
            'about',
            'volunteer'
        ]
    },

    /**
     * Prices for all the services we now, or may offer in the future
     * All prices in GBP Sterling
     */
    prices: {
        adoption: 90,
    },

    /**
     * These are the details for the new Captcha v3 from Google
     */
    recaptcha: {
        siteKey: "6LcnCdAlAAAAAHUq5R-W8B_8Fki2mEIRBI-A6Zg7",
    },

    /**
     * GetAddress.io is used for address autocompletion, as well as other things
     */
    getAddress: {
        apiKey: "XGSHIXPnmU6yjfh9ep9o2g39595",
        // host: 'https://api.getAddress.io', // for production
        host: "http://localhost", // for dev
        // https: true, // for production
        https: false, // dev
    },

    /**
     * This is so that the priorities of cats can be calculated automatically
     */
    catPriorities: {
        byPriority: {
            1: null,
            2: null,
            3: "home_to_home",
            4: null,
            5: null,
            6: null,
            7: "ready_for_adoption",
            8: null,
            9: null,
            10: null,
            11: "deceased",
            12: "unlisted",
            13: "waiting_list",
            14: "foster_home",
        },
        byState: {
            adopted: 15,
            deceased: 11,
            foster_home: 5,
            home_to_home: 3,
            ready_for_adoption: 7,
            unlisted: 12,
            "waiting_list:": 13,
        },
    },

    /**
     * Amount of listings per page (defaults)
     */
    listingsPerPage: {
        catsIndex: 25,
    },

    /**
     * Site searches
     */
    searches: {
        minAutoCompletionLength: 2,
        secondsToWaitToRegisterFinalSearch: 3,

    },

    affiliates: {
        products: [
            {
                name: "Reolink Camera",
                description: "Perfect for keeping an eye on your outdoor cats",
                url: "https://www.amazon.co.uk/gp/product/B08F71V8SH/ref=ox_sc_act_title_1?smid=ARGDLBF91D6J6&amp;psc=1&_encoding=UTF8&tag=bcr071-21&linkCode=ur2&linkId=a81b349e7f34ba033ccdc6aebc611c48&camp=1634&creative=6738",
            },
            {
                name: "Microsoft Office 365 Family edition",
                description: "A bargain at this price",
                url: "https://www.amazon.co.uk/gp/product/B00DRP537A/ref=ewc_pr_img_3?smid=AR9ZQCUDB1BRG&amp;psc=1&_encoding=UTF8&tag=bcr071-21&linkCode=ur2&linkId=c337a847981e1cfa0a4b6ea29964e1f6&camp=1634&creative=6738",
            },
            {
                name: "All new Echo Pop",
                description: "Possible the most cost effective version yet",
                url: "https://www.amazon.co.uk/gp/product/B09ZX9NP2W/ref=ewc_pr_img_4?smid=A3P5ROKL5A1OLE&amp;psc=1&_encoding=UTF8&tag=bcr071-21&linkCode=ur2&linkId=0a2632d56d74f170ec37fafddb8a2b56&camp=1634&creative=6738",
            },
            {
                name: "Easy solution for velcro collars for kittens",
                description:
                    "These should make it easier to create safe collars for your kittens",
                url: "https://www.amazon.co.uk/gp/product/B0BM8XLP78/ref=ewc_pr_img_6?smid=A3TNYC4MREY34U&amp;psc=1&_encoding=UTF8&tag=bcr071-21&linkCode=ur2&linkId=c69e9e090ace6b1a64dbc213688286e4&camp=1634&creative=6738",
            },
            {
                name: "Waterproof boot liner",
                description:
                    "If you are like me and you need to many continuous trips to the tip to dump cat litter, I highly recommend one of these boot liners",
                url: "https://www.amazon.co.uk/dp/B07HBCPL6C?ref_=cm_sw_r_cp_ud_dp_Q5Q3VR3ABT6PM8NS7QR7&_encoding=UTF8&tag=bcr071-21&linkCode=ur2&linkId=e2dd306bdcf4fc63857bb1f5f666429f&camp=1634&creative=6738",
            },
            {
                name: "biodegradable cat-poo liners",
                description:
                    "These 20L bin bags are an ideal size for a single cat litter change",
                url: "https://www.amazon.co.uk/Bamyko-Biodegradable-Compostable-Recycling-Wastebasket/dp/B0817MKY8V?pd_rd_w=Zctsx&content-id=amzn1.sym.6aea875e-359f-49f3-864f-cff62d586b6a%3Aamzn1.symc.ca948091-a64d-450e-86d7-c161ca33337b&pf_rd_p=6aea875e-359f-49f3-864f-cff62d586b6a&pf_rd_r=MERJ9MN8ZM5SZZWVW000&pd_rd_wg=BC02C&pd_rd_r=86fb8ea1-a5cc-4eba-8492-fddb2b76d2a3&pd_rd_i=B0817MKY8V&th=1&linkCode=ll1&tag=bcr071-21&linkId=c959baf79c766b3dd772f7d6db950a2e&language=en_GB&ref_=as_li_ss_tl",
            },
        ],
        providers: {
            amazon: {
                home: "https://www.amazon.co.uk/?&linkCode=sl2&tag=buttonscatrescue-21&linkId=f8887cbbd8c44c03a1e187f2fe8e2368&language=en_GB&ref_=as_li_ss_tl",
            },
        },
    },

    /**
     * Form details and defaults
     */
    forms: {
        /** The text that should be presented after a required field is shown */
        requiredText: "*",
    },

    /**
     * The site details. I could hard code it, but you never know...
     */
    site: {
        name: "Buttons Cat Rescue",
        url: "https://www.buttonscatrescue.co.uk"
    }
};
